import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Card = props => {
  const { name, slug, summary, thumbnail } = props

  const summaryMaxLength = 80

  return (
    <div className="bg-white h-full shadow-sm overflow-hidden group">
      <Link to={`/portfolio/${slug}`}>
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out z-10 h-full">
          <GatsbyImage
            image={thumbnail.gatsbyImageData}
            alt={name}
            className="z-0 image-gradient h-full"
          />
        </div>
        <div className="p-4 sm:p-5 -mt-32 z-40 relative">
          <h1 className="sm:text-lg text-white font-semibold">{name}</h1>
          <p className="text-sm sm:text-base text-gray-100 text-ellipsis">
            {`${summary.substring(0, summaryMaxLength)}...`}
          </p>
        </div>
      </Link>
    </div>
  )
}

Card.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    localFile: PropTypes.object,
  }),
}

export default Card

export const query = graphql`
  fragment PortfolioCard on ContentfulPortfolio {
    id
    name
    slug
    thumbnail {
      gatsbyImageData(layout: CONSTRAINED)
    }
    summary
    node_locale
    projectType
    createdAt
  }
`
