import React from "react"

import RequestBudgetButton from "./RequestBudgetButton"

const PageFooter = () => {
  return (
    <div className="container">
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="m-auto text-center">
            <h2 className="font-semibold text-2xl mb-6 mt-6">
              A sua empresa pode ser a próxima
            </h2>
            <p>
              Muito mais que um logotipo memorável, uma boa estratégia de
              branding aumento o valor de uma empresa.
            </p>
            <p>
              Vamos juntos construir uma marca autêntica e com personalidade
              para o seu negócio
            </p>
            <div className="mt-8">
              <RequestBudgetButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageFooter
