import { graphql } from "gatsby"
import React from "react"

import Layout from "../layouts/Layout"
import Cards from "../components/Cards"
import PageFooter from "../components/PageFooter"
import SiteMetadata from "../components/SiteMetadata"

import { filterByLocale } from "../helpers"

const portfolio = ({ data }) => {
  const currentLocale = "pt-BR"

  const cards = filterByLocale(data.portfolio, currentLocale)

  return (
    <Layout>
      <SiteMetadata
        title="Portfolio - Design de logos e marcas por Pri Bellafronte"
        description="Portfolio de Design Gráfico de Pri Bellafronte, com projetos de desenvolvimento de marcas, logos e mídias sociais"
        image={data.socialCoverImage.publicURL}
      />

      <div>
        <div className="container py-12 lg:pb-16">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-12 text-center">
            Portfolio
          </h1>
        </div>

        <div>
          <div>
            {cards.length > 0 ? (
              <>
                <div className></div>
                <Cards items={cards} />
              </>
            ) : (
              <p>Nenhum projeto nesta categoria.</p>
            )}
          </div>
        </div>
      </div>

      <div className="py-12 px-4 lg:py-16">
        <PageFooter />
      </div>
    </Layout>
  )
}

export default portfolio

export const query = graphql`
  query PortfolioQuery {
    portfolio: allContentfulPortfolio(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...PortfolioCard
      }
    }
    socialCoverImage: file(
      relativePath: { eq: "bellafronte-studio-social-cover.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          height: 400
          quality: 90
          layout: CONSTRAINED
        )
      }
      publicURL
    }
  }
`
